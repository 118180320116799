<template>
  <b-modal
    id="modal-mapping-value"
    title="Tiêu chí phù hợp"
    size="xl"
    centered
    hide-footer
    @hidden="resetModal"
    @show="onShow"
  >
    <b-overlay :show="loading">
      <template-table
        :column="column"
        :data="data"
        :tableAction="false"
        :selectAction="false"
        :searchAction="false"
        :selectSingle="false"
      >
        <template v-slot:body="{ item }">
          <td
            :class="{ 'text-mapping-success font-weight-bolder': item.isMaped }"
          >
            {{ item.name }}
          </td>
          <td>{{ getSelectedCoachValue(item.coachSelected) }}</td>
          <td>{{ getSelectedCustomerValue(item.customerSelected) }}</td>
        </template>
      </template-table>
    </b-overlay>
  </b-modal>
</template>

<script>
import intersectionBy from 'lodash/intersectionBy';
export default {
  name: 'ModalMappingValue',
  props: {
    customerId: {
      type: String,
      default: null,
    },
    groupId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      data: [],
      column: [
        {
          key: 'name',
          label: 'Tên tiêu chí',
          sortable: false,
          style: {
            width: '20%',
          },
        },
        {
          key: 'coach',
          label: 'Huấn luyện viên',
          sortable: false,
          style: {
            width: '40%',
          },
        },
        {
          key: 'customer',
          label: 'Khách hàng',
          sortable: false,
          style: {
            width: '40%',
          },
        },
      ],
    };
  },
  methods: {
    resetModal() {
      this.data = [];
      this.$emit('reset');
    },
    onShow() {
      this.getRuleMapping();
    },
    async getRuleMapping() {
      try {
        this.loading = true;
        const { meta, data } = await this.$api.get(
          '/TrainingGroup/RuleMapping',
          {
            params: {
              accountId: this.customerId,
              id: this.groupId,
            },
          },
        );

        if (!meta.success) return;
        this.data = await this.mappingValue(data);
      } catch (error) {
        return error;
      } finally {
        this.loading = false;
      }
    },
    async mappingValue(mappingData) {
      if (!mappingData) return;

      const { coachCriterion, patientCriterion } = mappingData;

      let dataAfterMappingValue = [];

      for (const [key, value] of Object.entries(coachCriterion)) {
        const criteria = await this.getCriteria(key, value, patientCriterion);

        if (criteria) {
          await this.checkItemDuplicate(criteria);

          dataAfterMappingValue.push(criteria);
        }
      }

      return dataAfterMappingValue;
    },
    getCriteria(key, value, patientCriterion) {
      return new Promise((reslove) => {
        if (!value) return reslove(null);

        switch (key) {
          case 'interestRuleList':
            return reslove({
              name: 'Sở thích',
              coachSelected: value?.filter((el) => el.selected) || [],
              customerSelected:
                patientCriterion['interestRuleList']?.filter(
                  (el) => el.selected,
                ) || [],
            });
          case 'consciousnessPracticeRuleList':
            return reslove({
              name: 'Thực hành tâm thức',
              coachSelected: value?.filter((el) => el.selected) || [],
              customerSelected:
                patientCriterion['consciousnessPracticeRuleList']?.filter(
                  (el) => el.selected,
                ) || [],
            });
          case 'languageRuleList':
            return reslove({
              name: 'Ngôn ngữ',
              coachSelected: value?.filter((el) => el.selected) || [],
              customerSelected:
                patientCriterion['languageRuleList']?.filter(
                  (el) => el.selected,
                ) || [],
            });
          case 'vegetarianRuleList':
            return reslove({
              name: 'Ăn chay',
              coachSelected: value?.filter((el) => el.selected) || [],
              customerSelected:
                patientCriterion['vegetarianRuleList']?.filter(
                  (el) => el.selected,
                ) || [],
            });
          case 'genderRuleList':
            return reslove({
              name: 'Giới tính',
              coachSelected: value?.filter((el) => el.selected) || [],
              customerSelected:
                patientCriterion['genderRuleList']?.filter(
                  (el) => el.selected,
                ) || [],
            });
          case 'personalityRuleList':
            return reslove({
              name: 'Tính cách',
              coachSelected: value?.filter((el) => el.selected) || [],
              customerSelected:
                patientCriterion['personalityRuleList']?.filter(
                  (el) => el.selected,
                ) || [],
            });
          case 'favouriteSportRuleList':
            return reslove({
              name: 'Môn thể thao yêu thích',
              coachSelected: value?.filter((el) => el.selected) || [],
              customerSelected:
                patientCriterion['favouriteSportRuleList']?.filter(
                  (el) => el.selected,
                ) || [],
            });
          case 'religionRuleList':
            return reslove({
              name: 'Tôn giáo',
              coachSelected: value?.filter((el) => el.selected) || [],
              customerSelected:
                patientCriterion['religionRuleList']?.filter(
                  (el) => el.selected,
                ) || [],
            });
          case 'locallyRuleList':
            return reslove({
              name: 'Tính địa phương',
              coachSelected: value?.filter((el) => el.selected) || [],
              customerSelected:
                patientCriterion['locallyRuleList']?.filter(
                  (el) => el.selected,
                ) || [],
            });
          case 'workingHourRuleList':
            return reslove({
              name: 'Khung giờ làm việc',
              coachSelected: value?.filter((el) => el.selected) || [],
              customerSelected:
                patientCriterion['workingHourRuleList']?.filter(
                  (el) => el.selected,
                ) || [],
            });
          case 'levelOfDiabetesRuleList':
            return reslove({
              name: 'Mức độ đái tháo đường',
              coachSelected: value?.filter((el) => el.selected) || [],
              customerSelected:
                patientCriterion['levelOfDiabetesRuleList']?.filter(
                  (el) => el.selected,
                ) || [],
            });

          default:
            return reslove(null);
        }
      });
    },
    checkItemDuplicate(criteria) {
      return new Promise((reslove) => {
        //Get common Items of two Items array => Status: 0 (Unchanged)
        let commonItems = intersectionBy(
          criteria.coachSelected,
          criteria.customerSelected,
          'value',
        );

        if (
          commonItems.length ||
          (criteria.coachSelected.length && !criteria.customerSelected.length)
        ) {
          criteria.isMaped = true;
        } else {
          criteria.isMaped = false;
        }
        reslove();
      });
    },
    getSelectedCoachValue(arr) {
      if (!arr.length) return null;
      return arr.map((el) => el.text).join(', ');
    },
    getSelectedCustomerValue(arr) {
      if (!arr.length) return null;
      return arr.map((el) => el.text).join(', ');
    },
  },
};
</script>

<style lang="scss" scoped>
.text-mapping-success {
  color: #008479 !important;
}
</style>
